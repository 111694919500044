import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import logoImg from "../../../../assets/images/logo.png";
const NavLogo = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;


    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
        <React.Fragment>
            <div className="navbar-brand header-logo" style={{ backgroundColor: 'white' }}>
                 <Link to='/auth/login' className="b-brand">
                    <div>
                        <img src={logoImg} alt="" />
                    </div>
                 </Link>
                <Link to='#' className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({type: actionType.COLLAPSE_MENU})}><span /></Link>
            </div>
        </React.Fragment>
    );
};

export default NavLogo;
