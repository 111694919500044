import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [

    { exact: true, guard: GuestGuard, path: '/auth/Login', component: lazy(() => import('./views/auth/Login')) },
    { exact: true, guard: GuestGuard, path: '/auth/license', component: lazy(() => import('./views/auth/license')) },
    //{ exact: true, guard: GuestGuard, path: '/auth/ApproveInv', component: lazy(() => import('./views/CarInstallment/ApproveInv')) },

  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [

        //---------------------------------- Administration -----------------------------------------------------\\

        { exact: true, path: '/Company', component: lazy(() => import('./views/General/Company')) },

        { exact: true, path: '/Branches', component: lazy(() => import('./views/General/Branches')) },

        { exact: true, path: '/FinancialYear', component: lazy(() => import('./views/General/FinancialYear')) },


        { exact: true, path: '/Clients', component: lazy(() => import('./views/General/Clients')) },

        { exact: true, path: '/Suppliers', component: lazy(() => import('./views/General/Suppliers')) },


        { exact: true, path: '/Users', component: lazy(() => import('./views/General/Users')) },

        { exact: true, path: '/Employee', component: lazy(() => import('./views/General/Employee')) },

        { exact: true, path: '/GeneralSetting', component: lazy(() => import('./views/General/GeneralSetting')) },

        { exact: true, path: '/Banks', component: lazy(() => import('./views/General/Banks')) },
        { exact: true, path: '/Areas', component: lazy(() => import('./views/General/Areas')) },


        { exact: true, path: '/SalesMovement', component: lazy(() => import('./views/General/SalesMovement')) },

        { exact: true, path: '/PostEInvoice', component: lazy(() => import('./views/General/PostEInvoice')) },

        { exact: true, path: '/PosTrnsPost', component: lazy(() => import('./views/General/PosTrnsPost')) },

        { exact: true, path: '/Cmsn', component: lazy(() => import('./views/General/Cmsn')) },




        



        { exact: true, path: '/mainPage', component: lazy(() => import('./views/dashboard/DashDefault')) },

        
        //-------------------------------------- Accounting -----------------------------------------------------\\

        
        { exact: true, path: '/Accounts/AccAccount', component: lazy(() => import('./views/Accounts/AccAccount')) },

        { exact: true, path: '/Accounts/AccOpenBalance', component: lazy(() => import('./views/Accounts/AccOpenBalance')) },

        { exact: true, path: '/Accounts/AccDaily', component: lazy(() => import('./views/Accounts/AccDaily')) },

        { exact: true, path: '/Accounts/AccBook', component: lazy(() => import('./views/Accounts/AccBook')) },

        { exact: true, path: '/Accounts/AccSettings', component: lazy(() => import('./views/Accounts/AccSettings')) },

        { exact: true, path: '/Accounts/AccCostCenter', component: lazy(() => import('./views/Accounts/AccCostCenter')) },



        { exact: true, path: '/GL/DailyEntry', component: lazy(() => import('./views/AccGeneralLedger/DailyEntry')) },

        { exact: true, path: '/GL/PostEntry', component: lazy(() => import('./views/AccGeneralLedger/PostEntry')) },


        { exact: true, path: '/GL/ReceiptsCash', component: lazy(() => import('./views/AccGeneralLedger/ReceiptsCash')) },

        { exact: true, path: '/GL/ReceiptsBank', component: lazy(() => import('./views/AccGeneralLedger/ReceiptsBank')) },


        { exact: true, path: '/GL/BillExchangeCash', component: lazy(() => import('./views/AccGeneralLedger/BillExchangeCash')) },

        { exact: true, path: '/GL/BillExchangeBank', component: lazy(() => import('./views/AccGeneralLedger/BillExchangeBank')) },


        { exact: true, path: '/GL/ReceiptsInv', component: lazy(() => import('./views/AccGeneralLedger/ReceiptsInv')) },
        { exact: true, path: '/GL/BillExchangeInv', component: lazy(() => import('./views/AccGeneralLedger/BillExchangeInv')) },

        
        
        //-------------------------------------- Accounting Reprot-----------------------------------------------------\\

        { exact: true, path: '/GLReport/AccStatement', component: lazy(() => import('./views/AccGeneralLedger/Report/AccountStatment')) },

        { exact: true, path: '/GLReport/DailyMovement', component: lazy(() => import('./views/AccGeneralLedger/Report/DailyMovement')) },

        { exact: true, path: '/GLReport/BalanceReview', component: lazy(() => import('./views/AccGeneralLedger/Report/BalanceReview')) },


        { exact: true, path: '/GLReport/IncoumingList', component: lazy(() => import('./views/AccGeneralLedger/Report/IncoumingList')) },

        { exact: true, path: '/GLReport/FinancialCenter', component: lazy(() => import('./views/AccGeneralLedger/Report/FinancialCenter')) },



        { exact: true, path: '/GLReport/AccountBalance', component: lazy(() => import('./views/AccGeneralLedger/Report/AccountBalance')) },

        { exact: true, path: '/GLReport/AccOpenBalance', component: lazy(() => import('./views/AccGeneralLedger/Report/AccOpenBalance')) },

        { exact: true, path: '/GLReport/DailyEntryReport', component: lazy(() => import('./views/AccGeneralLedger/Report/DailyEntryReport')) },

        { exact: true, path: '/GLReport/DailySalesReport', component: lazy(() => import('./views/AccGeneralLedger/Report/DailySalesReport')) },

        { exact: true, path: '/GLReport/InvoiceTax', component: lazy(() => import('./views/AccGeneralLedger/Report/InvoiceTax')) },

        { exact: true, path: '/GLReport/CashReceipts', component: lazy(() => import('./views/AccGeneralLedger/Report/CashReceipts')) },

        { exact: true, path: '/GLReport/BankReceipts', component: lazy(() => import('./views/AccGeneralLedger/Report/BankReceipts')) },

        { exact: true, path: '/GLReport/CashPaymentVoucher', component: lazy(() => import('./views/AccGeneralLedger/Report/CashPaymentVoucher')) },

        { exact: true, path: '/GLReport/BankPaymentVoucher', component: lazy(() => import('./views/AccGeneralLedger/Report/BankPaymentVoucher')) },




        { exact: true, path: '/GLReport/ProfitLosses', component: lazy(() => import('./views/AccGeneralLedger/Report/ProfitLosses')) },


        { exact: true, path: '/GLReport/ListFinanceCenter', component: lazy(() => import('./views/AccGeneralLedger/Report/ListFinanceCenter')) },

        { exact: true, path: '/GLReport/RevenuesExpenses', component: lazy(() => import('./views/AccGeneralLedger/Report/RevenuesExpenses')) },
        { exact: true, path: '/GLReport/CostCenterReport', component: lazy(() => import('./views/AccGeneralLedger/Report/CostCenterReport')) },
        { exact: true, path: '/GLReport/AccCostCenterReport', component: lazy(() => import('./views/AccGeneralLedger/Report/AccCostCenterReport')) },
        { exact: true, path: '/GLReport/CostCenterStatment', component: lazy(() => import('./views/AccGeneralLedger/Report/CostCenterStatment')) },


        { exact: true, path: '/GLReport/AccAccountList', component: lazy(() => import('./views/AccGeneralLedger/Report/AccAccountList')) },
        { exact: true, path: '/GLReport/DebtAgesReport', component: lazy(() => import('./views/AccGeneralLedger/Report/DebtAgesReport')) },

        { exact: true, path: '/GLReport/BalanceReviewDetail', component: lazy(() => import('./views/AccGeneralLedger/Report/BalanceReviewDetail')) },
        { exact: true, path: '/GLReport/GeneralBalance', component: lazy(() => import('./views/AccGeneralLedger/Report/GeneralBalance')) },
        { exact: true, path: '/GLReport/AccountBalanceAll', component: lazy(() => import('./views/AccGeneralLedger/Report/AccountBalanceAll')) },

        { exact: true, path: '/GLReport/BalanceReviewTotal', component: lazy(() => import('./views/AccGeneralLedger/Report/BalanceReviewTotal')) },

        { exact: true, path: '/GLReport/ApproveBalance', component: lazy(() => import('./views/AccGeneralLedger/Report/ApproveBalance')) },


        //-------------------------------------- Accounting Reprot Tax-----------------------------------------------------\\

        
        { exact: true, path: '/GLReportTax/PurchaseVatReport', component: lazy(() => import('./views/AccGeneralLedger/ReportTax/PurchaseVatReport')) },
        { exact: true, path: '/GLReportTax/SalesVatReport', component: lazy(() => import('./views/AccGeneralLedger/ReportTax/SalesVatReport')) },


        { exact: true, path: '/GLReportTax/PurchaseItemVat', component: lazy(() => import('./views/AccGeneralLedger/ReportTax/PurchaseItemVatReport')) },
        { exact: true, path: '/GLReportTax/SalesItemVat', component: lazy(() => import('./views/AccGeneralLedger/ReportTax/SalesItemVatReport')) },
        { exact: true, path: '/GLReport/TaxReturn', component: lazy(() => import('./views/AccGeneralLedger/ReportTax/TaxReturn')) },
        


        

        //----------------------------------Stores -----------------------------------------------------\\

        { exact: true, path: '/Store', component: lazy(() => import('./views/Stores/Store')) },

        { exact: true, path: '/ItemGroup', component: lazy(() => import('./views/Stores/ItemGroup')) },

        { exact: true, path: '/Unit', component: lazy(() => import('./views/Stores/Unit')) },

        { exact: true, path: '/Item', component: lazy(() => import('./views/Stores/Item')) },

        { exact: true, path: '/ItemOpenBalance', component: lazy(() => import('./views/Stores/ItemOpenBalance')) },
        
        //----------------------------------StoresTrans -----------------------------------------------------\\
        { exact: true, path: '/StoresTrans/StoreOpenBalance', component: lazy(() => import('./views/StoresTrans/StoreOpenBalance')) },
        { exact: true, path: '/StoresTrans/ReceivingTrans', component: lazy(() => import('./views/StoresTrans/ReceivingTrans')) },
        { exact: true, path: '/StoresTrans/TransFormStore', component: lazy(() => import('./views/StoresTrans/TransFormStore')) },
        { exact: true, path: '/StoresTrans/TranstoStore', component: lazy(() => import('./views/StoresTrans/TranstoStore')) },

        { exact: true, path: '/StoresTrans/DestructionItems', component: lazy(() => import('./views/StoresTrans/DestructionItems')) },
        { exact: true, path: '/StoresTrans/ExchangeTrans', component: lazy(() => import('./views/StoresTrans/ExchangeTrans')) },
        { exact: true, path: '/StoresTrans/StoreInventory', component: lazy(() => import('./views/StoresTrans/StoreInventory')) },
        { exact: true, path: '/StoresTrans/AccountItemCost', component: lazy(() => import('./views/StoresTrans/AccountItemCost')) },
        
        //-------------------------------------- StoresTrans Reprot-----------------------------------------------------\\
        { exact: true, path: '/StoresReport/StoreTransReport', component: lazy(() => import('./views/StoresTrans/Report/StoreTransReport')) },
        { exact: true, path: '/StoresReport/StoreItemReport', component: lazy(() => import('./views/StoresTrans/Report/StoreItemReport')) },
        { exact: true, path: '/StoresReport/ItemReport', component: lazy(() => import('./views/StoresTrans/Report/ItemReport')) },

        { exact: true, path: '/StoresReport/ItemCostReport', component: lazy(() => import('./views/StoresTrans/Report/ItemCostReport')) },
        { exact: true, path: '/StoresReport/StoreCostItemReport', component: lazy(() => import('./views/StoresTrans/Report/StoreCostItemReport')) },

        { exact: true, path: '/StoresReport/StoreItemPriceReport', component: lazy(() => import('./views/StoresTrans/Report/StoreItemPriceReport')) },

        { exact: true, path: '/StoresReport/ItemMovementReport', component: lazy(() => import('./views/StoresTrans/Report/ItemMovementReport')) },
        { exact: true, path: '/StoresReport/ItemMovementSmryReport', component: lazy(() => import('./views/StoresTrans/Report/ItemMovementSmryReport')) },

        { exact: true, path: '/StoresReport/ItemStoreBalanceReport', component: lazy(() => import('./views/StoresTrans/Report/ItemStoreBalanceReport')) },

        //-------------------------------------Purchases-----------------------------------------------------------\\
        { exact: true, path: '/PurchaseTrans/PurchaseOrder', component: lazy(() => import('./views/PurchaseTrans/PurchaseOrder')) },

        { exact: true, path: '/PurchaseTrans/PurchaseInv', component: lazy(() => import('./views/PurchaseTrans/PurchaseInv')) },
        { exact: true, path: '/PurchaseTrans/PurchaseRtnInv', component: lazy(() => import('./views/PurchaseTrans/PurchaseRtnInv')) },
        { exact: true, path: '/PurchaseTrans/HotelPInv', component: lazy(() => import('./views/PurchaseTrans/HotelPInv')) },
        { exact: true, path: '/PurchaseTrans/SpendInv', component: lazy(() => import('./views/PurchaseTrans/SpendInv')) },
        
        //-------------------------------------- Purchase Trans Reprot-----------------------------------------------------\\
        { exact: true, path: '/PurchaseReport/PurchaseTransReport', component: lazy(() => import('./views/PurchaseTrans/Report/PurchaseTransReport')) },
        { exact: true, path: '/PurchaseReport/SupplierDebtReport', component: lazy(() => import('./views/PurchaseTrans/Report/SupplierDebtReport')) },
        { exact: true, path: '/PurchaseTrans/PurchaseTransAllReport', component: lazy(() => import('./views/PurchaseTrans/Report/PurchaseTransAllReport')) },
        
        
        //-------------------------------------Sales-----------------------------------------------------------\\
        { exact: true, path: '/SalesTrans/SalesOffer', component: lazy(() => import('./views/SalesTrans/SalesOffer')) },
        { exact: true, path: '/SalesTrans/SalesOrder', component: lazy(() => import('./views/SalesTrans/SalesOrder')) },

        { exact: true, path: '/SalesTrans/SalesInv', component: lazy(() => import('./views/SalesTrans/SalesInv')) },
        { exact: true, path: '/SalesTrans/SalesRtrnInv', component: lazy(() => import('./views/SalesTrans/SalesRtrnInv')) },

        { exact: true, path: '/SalesTrans/SalesFctyInv', component: lazy(() => import('./views/SalesTrans/SalesFctyInv')) },

        
        { exact: true, path: '/SalesTrans/SalesInv2', component: lazy(() => import('./views/SalesTrans/SalesInv2')) },

        { exact: true, path: '/SalesTrans/SalesDiscount', component: lazy(() => import('./views/SalesTrans/SalesDiscount')) },
        { exact: true, path: '/SalesTrans/ItemClient', component: lazy(() => import('./views/SalesTrans/ItemClient')) },
        { exact: true, path: '/SalesTrans/ItemUnitPrice', component: lazy(() => import('./views/SalesTrans/ItemUnitPrice')) },

        { exact: true, path: '/SalesTrans/POS1', component: lazy(() => import('./views/SalesTrans/POS1')) },
        { exact: true, path: '/SalesTrans/PosOffer1', component: lazy(() => import('./views/SalesTrans/PosOffer1')) },

        { exact: true, path: '/SalesTrans/HotelInv', component: lazy(() => import('./views/SalesTrans/HotelInv')) },

        { exact: true, path: '/SalesTrans/Extracts', component: lazy(() => import('./views/SalesTrans/Extracts')) },

        { exact: true, path: '/SalesTrans/SalesOrderClient', component: lazy(() => import('./views/SalesTrans/SalesOrderClient')) },

        { exact: true, path: '/SalesTrans/DiscountNot', component: lazy(() => import('./views/SalesTrans/DiscountNot')) },

        
        //-------------------------------------- Sales Trans Reprot-----------------------------------------------------\\
        { exact: true, path: '/SalesReport/SalesTransReport', component: lazy(() => import('./views/SalesTrans/Report/SalesTransReport')) },
        { exact: true, path: '/SalesReport/ClientDebtReport', component: lazy(() => import('./views/SalesTrans/Report/ClientDebtReport')) },
        { exact: true, path: '/SalesReport/SalesTransAllReport', component: lazy(() => import('./views/SalesTrans/Report/SalesTransAllReport')) },
        { exact: true, path: '/SalesReport/SalesTransAllReport', component: lazy(() => import('./views/SalesTrans/Report/SalesTransAllReport')) },

        { exact: true, path: '/SalesReport/SalesProfitReport', component: lazy(() => import('./views/SalesTrans/Report/SalesProfitReport')) },
        { exact: true, path: '/SalesReport/ItemProfitReport', component: lazy(() => import('./views/SalesTrans/Report/ItemProfitReport')) },
        { exact: true, path: '/SalesReport/ItemSalesReport', component: lazy(() => import('./views/SalesTrans/Report/ItemSalesReport')) },

        { exact: true, path: '/SalesReport/SalesMngrReport', component: lazy(() => import('./views/SalesTrans/Report/SalesMngrReport')) },

        { exact: true, path: '/SalesReport/SalesInvPayReport', component: lazy(() => import('./views/SalesTrans/Report/SalesInvPayReport')) },

        
        { exact: true, path: '/SalesReport/SalesMonthReport', component: lazy(() => import('./views/SalesTrans/Report/SalesMonthReport')) },
        { exact: true, path: '/SalesReport/SalesItemMonthReport', component: lazy(() => import('./views/SalesTrans/Report/SalesItemMonthReport')) },


        
        //------------------------------------------------ Production -----------------------------------------------------\\
        { exact: true, path: '/Production/StudyCostProduct', component: lazy(() => import('./views/Production/StudyCostProduct')) },
        { exact: true, path: '/Production/ProductionOrder', component: lazy(() => import('./views/Production/ProductionOrder')) },
        { exact: true, path: '/Production/Production', component: lazy(() => import('./views/Production/Production')) },
        
        //------------------------------------------------ Production Reports-----------------------------------------------------\\
        { exact: true, path: '/ProductionReport/ProductionReport', component: lazy(() => import('./views/Production/Report/ProductionReport')) },
        





        //------------------------------------------Car Installment -----------------------------------------------------\\
        
        
         //-----------------------------------------
        { exact: true, path: '/CarCompany', component: lazy(() => import('./views/General/Cars/CarCompany')) },
        { exact: true, path: '/CarType', component: lazy(() => import('./views/General/Cars/CarType')) },
        { exact: true, path: '/CarColor', component: lazy(() => import('./views/General/Cars/CarColor')) },
        { exact: true, path: '/CarCategory', component: lazy(() => import('./views/General/Cars/CarCategory')) },

        //{ exact: true, path: '/Cars', component: lazy(() => import('./views/General/Cars/Cars')) },

        { exact: true, path: '/CarInstallment/CarOpenBalance', component: lazy(() => import('./views/CarInstallment/CarOpenBalance')) },

        


        { exact: true, path: '/CarInstallment/CarPurchaseOrdr', component: lazy(() => import('./views/CarInstallment/CarPurchaseOrdr')) },
        { exact: true, path: '/CarInstallment/CarAprvPurchaseOrdr', component: lazy(() => import('./views/CarInstallment/CarAprvPurchaseOrdr')) },
        { exact: true, path: '/CarInstallment/CarPurchaseInv', component: lazy(() => import('./views/CarInstallment/CarPurchaseInv')) },
        { exact: true, path: '/CarInstallment/CarPurchaseRtrn', component: lazy(() => import('./views/CarInstallment/CarPurchaseRtrn')) },
        { exact: true, path: '/CarInstallment/SupplierPay', component: lazy(() => import('./views/CarInstallment/SupplierPay')) },
        { exact: true, path: '/CarInstallment/CarSpend', component: lazy(() => import('./views/CarInstallment/CarSpend')) },

         
        { exact: true, path: '/CarPurchaseReport/PurchaseTransReport', component: lazy(() => import('./views/CarInstallment/Report/PurchaseTransReport')) },
       
        //-------------------------------
        { exact: true, path: '/CarInstallment/CarSalesPrice', component: lazy(() => import('./views/CarInstallment/CarSalesPrice')) },
        { exact: true, path: '/CarInstallment/SalesOffer', component: lazy(() => import('./views/CarInstallment/SalesOffer')) },

        //{ exact: true, path: '/CarInstallment/CarSalesOrdr', component: lazy(() => import('./views/CarInstallment/CarSalesOrdr')) },
        //{ exact: true, path: '/CarInstallment/CarAprvSalesOrdr', component: lazy(() => import('./views/CarInstallment/CarAprvSalesOrdr')) },
        
        { exact: true, path: '/CarInstallment/CarSalesInv', component: lazy(() => import('./views/CarInstallment/CarSalesInv')) },
        { exact: true, path: '/CarInstallment/CarSalesRtrn', component: lazy(() => import('./views/CarInstallment/CarSalesRtrn')) },
        { exact: true, path: '/CarInstallment/CarSalesPay', component: lazy(() => import('./views/CarInstallment/CarSalesPay')) },

        { exact: true, path: '/CarSalesReport/SalesTransReport', component: lazy(() => import('./views/CarInstallment/Report/SalesTransReport')) },
        { exact: true, path: '/CarSalesReport/SalesProfitReport', component: lazy(() => import('./views/CarInstallment/Report/SalesProfitReport')) },


        { exact: true, path: '/CarSalesReport/CarStoreReport', component: lazy(() => import('./views/CarInstallment/Report/CarStoreReport')) },
        

        //------------------------------------------ Butchers -----------------------------------------------------\\
        
        { exact: true, path: '/Butchers/Hall', component: lazy(() => import('./views/Butchers/BHall')) },
        { exact: true, path: '/Butchers/Item', component: lazy(() => import('./views/Butchers/BItem')) },
        { exact: true, path: '/Butchers/SalesInv', component: lazy(() => import('./views/Butchers/BSalesInv')) },
        { exact: true, path: '/Butchers/SalesInvAll', component: lazy(() => import('./views/Butchers/BSalesInvAll')) },

        { exact: true, path: '/Butchers/BSalesTransReport', component: lazy(() => import('./views/Butchers/Report/BSalesTransReport')) },

        
        
        //------------------------------------------------ HR -----------------------------------------------------\\
        { exact: true, path: '/HR/Mangement', component: lazy(() => import('./views/HR/Mangement')) },
        { exact: true, path: '/HR/JobSection', component: lazy(() => import('./views/HR/JobSection')) },
        
        { exact: true, path: '/HR/Job', component: lazy(() => import('./views/HR/Job')) },
        { exact: true, path: '/HR/JobType', component: lazy(() => import('./views/HR/JobType')) },
        { exact: true, path: '/HR/JobDegree', component: lazy(() => import('./views/HR/JobDegree')) },

        
        { exact: true, path: '/HR/AllowanceType', component: lazy(() => import('./views/HR/AllowanceType')) },
        { exact: true, path: '/HR/Reward', component: lazy(() => import('./views/HR/Reward')) },
        { exact: true, path: '/HR/Penalty', component: lazy(() => import('./views/HR/Penalty')) },


        { exact: true, path: '/HR/ExtraSalay', component: lazy(() => import('./views/HR/ExtraSalay')) },
        { exact: true, path: '/HR/AlarmType', component: lazy(() => import('./views/HR/AlarmType')) },
        { exact: true, path: '/HR/VacationType', component: lazy(() => import('./views/HR/VacationType')) },


        { exact: true, path: '/HR/HrDocType', component: lazy(() => import('./views/HR/HrDocType')) },
        { exact: true, path: '/HR/InsurState', component: lazy(() => import('./views/HR/InsurState')) },
        { exact: true, path: '/HR/MedInsur', component: lazy(() => import('./views/HR/MedInsur')) },

        { exact: true, path: '/HR/EmpLocation', component: lazy(() => import('./views/HR/EmpLocation')) },

        { exact: true, path: '/HR/EndWorkRsons', component: lazy(() => import('./views/HR/EndWorkRsons')) },


        { exact: true, path: '/HR/Employee', component: lazy(() => import('./views/HR/Trans/Employee')) },
        { exact: true, path: '/HR/EmpAdmin', component: lazy(() => import('./views/HR/Trans/EmpAdmin')) },

        { exact: true, path: '/HR/EmpTrans', component: lazy(() => import('./views/HR/Trans/EmpTrans')) },
        { exact: true, path: '/HR/MothSalary', component: lazy(() => import('./views/HR/Trans/MothSalary')) },
        { exact: true, path: '/HR/MothSalaryAction', component: lazy(() => import('./views/HR/Trans/MothSalaryAction')) },
        //------------------------------------------------ HR Report -----------------------------------------------------\\
        { exact: true, path: '/HRReport/SalaryReport', component: lazy(() => import('./views/HR/Report/SalaryReport')) },






        //------------------------------------------------ Real Estate -----------------------------------------------------\\
        { exact: true, path: '/Real/RealProject', component: lazy(() => import('./views/Real/RealProject')) },
        { exact: true, path: '/Real/Building', component: lazy(() => import('./views/Real/Building')) },
        { exact: true, path: '/Real/Apartment', component: lazy(() => import('./views/Real/Apartment')) },
        { exact: true, path: '/Real/Clients', component: lazy(() => import('./views/Real/RealClients')) },
        { exact: true, path: '/Real/Reserve', component: lazy(() => import('./views/Real/RealReserve')) },
        { exact: true, path: '/Real/Contract', component: lazy(() => import('./views/Real/RealContract')) },
        { exact: true, path: '/Real/Installment', component: lazy(() => import('./views/Real/Installment')) },


        { exact: true, path: '/RealReport/ApartmentStateRprt', component: lazy(() => import('./views/Real/Report/ApartmentStateRprt')) },
        { exact: true, path: '/RealReport/RealReserveRprt', component: lazy(() => import('./views/Real/Report/RealReserveRprt')) },
        { exact: true, path: '/RealReport/RealContractRprt', component: lazy(() => import('./views/Real/Report/RealContractRprt')) },
        { exact: true, path: '/RealReport/RealClentDeptRprt', component: lazy(() => import('./views/Real/Report/RealClentDeptRprt')) },
        { exact: true, path: '/RealReport/RealContractClinetPrnt', component: lazy(() => import('./views/Real/Report/RealContractClinetPrnt')) },


        //------------------------------------------------------------------------------------------------------------------------\\
        { exact: true, path: '/Extract/ExtractContract', component: lazy(() => import('./views/Extract/ExtractContract')) },
        { exact: true, path: '/Extract/ExtractVoice1', component: lazy(() => import('./views/Extract/ExtractVoice1')) },
        //{ exact: true, path: '/Extract/ExtractVoice2', component: lazy(() => import('./views/Extract/ExtractVoice2')) },

        

      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL}/>
      }
    ]
  }
];

export default routes;
